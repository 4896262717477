/* eslint-disable semi */
import en from './locales/en.json';
import de from './locales/de.json';
import nl from './locales/nl.json';
import it from './locales/it.json';

export default {
  en,
  de,
  nl,
  it
};
